<template>
  <main>
    <trac-loading v-if="isLoading" />
    <div class="" v-if="reportSent">
      <div class="mt-6 text-center p-10">
        <div class="flex justify-center">
          <img class="w-1/5" src="./../../assets/images/sucess-mark.png" alt="">
        </div>

        <h3 class="text-2xl font-semibold">Form Submitted</h3>
        <p class="text-sm mt-2">Your form has been submitted successfully. You will receive an acknowledgement email
          shortly.</p>

        <trac-button @button-clicked="reportSent = false;" class="primary-button px-10 mx-auto mt-10 py-3 font-medium">
          Done
        </trac-button>
      </div>
    </div>
    <div v-else class="mr-4 md:mr-0 mt-4 lg:w-7/12">
      <h1 class=" text-xl font-semibold text-primaryBlue">User Support Form</h1>
      <p class="text-sm font-semibold my-4">Traction Support Form</p>
      <trac-validation-observer slim v-slot="{ handleSubmit }" ref="observer">
        <form @submit.prevent="handleSubmit(sendSupportMessage)">
          <div class="grid grid-cols-2 gap-y-3 lg:ml-10">
            <div class="flex items-center text-xs">
              <label for="name" class="text-sm font-medium">Name</label>
            </div>
            <trac-validation-provider name="Name" rules="required" v-slot="{ errors }">
              <trac-input disabled type="text" v-model="supportForm.name" class="" id="name"></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <!-- <div class="flex items-center text-xs">
              <label for="last-name" class="text-sm font-medium">Last Name</label>
            </div>
            <trac-validation-provider name="last name" rules="required" v-slot="{ errors }">
              <trac-input disabled type="text" v-model="supportForm.lastname" class="" id="last-name"></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider> -->


            <div class="flex items-center text-xs">
              <label for="email" class="text-sm font-medium">Email</label>
            </div>
            <trac-validation-provider name="email" rules="required|email" v-slot="{ errors }">
              <trac-input disabled type="text" v-model="supportForm.email" class="" id="email"></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <div class="flex items-center text-xs">
              <label for="phone-number" class="text-sm font-medium">Phone Number</label>
            </div>
            <trac-validation-provider name="phone number" rules="required" v-slot="{ errors }">
              <trac-input type="text" v-model="supportForm.phone" class="" id="phone-number"></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <div class="flex items-center text-xs">
              <label for="business-name" class="text-sm font-medium">Business Name</label>
            </div>
            <trac-validation-provider name="business name" rules="required" v-slot="{ errors }">
              <trac-input type="text" v-model="supportForm.businessName" class="" id="business-name"></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <div class="flex items-center text-xs">
              <label for="issue-type" class="text-sm font-medium">Issue Type</label>
            </div>
            <trac-validation-provider name="issue type" rules="required" v-slot="{ errors }">
              <select @input="supportForm.issueSubTypes = null" v-model="supportForm.issueTypes"
                class="w-full text-xs p-3 border border-lightGray rounded" id="issue-type">
                <option value="">Select Issue Type</option>
                <option :value="issue.type" v-for="(issue, index) in issues" :key="index">{{ issue.type }}</option>
              </select>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <div class="flex items-center text-xs">
              <label for="issue-sub-type" class="text-sm font-medium">Issue Sub-type</label>
            </div>
            <trac-validation-provider name="issue sub type" rules="required" v-slot="{ errors }">
              <select v-model="supportForm.issueSubTypes" class="w-full text-xs p-3 border border-lightGray rounded" id="issue-sub-type">
                <option value="">Select Issue Sub-type</option>
                <option :value="subIssues.type" v-for="(subIssues, index) in (appliedSubTypes || {}).subIssues"
                  :key="index">{{ subIssues.type }}</option>
              </select>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <div class="flex items-center text-xs">
              <label for="subject" class="text-sm font-medium">Subject</label>
            </div>
            <trac-validation-provider name="subject" rules="required" v-slot="{ errors }">
              <trac-input type="text" v-model="supportForm.subject" class="" id="subject"></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <div class="flex items-center text-xs">
              <label for="description" class="text-sm font-medium">Description</label>
            </div>
            <trac-validation-provider name="description" rules="required" v-slot="{ errors }">
              <trac-textarea v-model="supportForm.description" type="text" class="" id="description"></trac-textarea>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

          </div>

          <div class="flex justify-end mt-10">
            <trac-button buttonType="submit" class="px-16">Submit</trac-button>
          </div>
        </form>
      </trac-validation-observer>
      <!-- <div class="" v-html="zohoForm"></div> -->
    </div>
  </main>
</template>

<script>
import { zohoForm } from './Zohoform';
import { eventBus } from "./../../main";
import { GET_CURRENT_AUTH_USER, GET_USER_BUSINESS_DATA, GET_USER_DATA } from '../../browser-db-config/localStorage';

export default {
  data() {
    return {
      zohoForm: zohoForm,
      isLoading: false,
      reportSent: false,
      issues: null,
      supportForm: {
        name: '',
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        businessName: '',
        issueTypes: '',
        issueSubTypes: '',
        subject: '',
        description: '',
        issueType: ''
      }
    };
  },
  created() {
    this.fetchSupportIssues();
    const userData = GET_USER_DATA();
    const businessData = GET_USER_BUSINESS_DATA();

    const userName = userData.name || '';
    this.supportForm.name = userName;
    this.supportForm.firstname = userName.split(' ')[0];
    this.supportForm.lastname = userName.split(' ')[1];
    this.supportForm.businessName = businessData.name;
    this.supportForm.email = businessData.email;
    this.supportForm.phone = businessData.phone;
  },
  computed: {
    appliedSubTypes() {
      return (this.issues || []).find(issue => issue.type === this.supportForm.issueTypes);
    }
  },
  methods: {
    async fetchSupportIssues() {
      const res = await this.$store.dispatch('GET_SUPPORT_ISSUES');
      if (res.status) {
        this.issues = ((res.data || {}).supportIssues || []);
      }
    },
    async sendSupportMessage() {
      this.isLoading = true;

      const userData = GET_USER_DATA();
      const userName = userData.name || '';

      const payload = {
        name: userName,
        firstName: this.supportForm.firstname,
        lastName: this.supportForm.lastname,
        email: this.supportForm.email,
        issueType: this.supportForm.issueTypes,
        subIssue: this.supportForm.issueSubTypes,
        subject: this.supportForm.subject,
        description: this.supportForm.description
      };

      const res = await this.$store.dispatch('SEND_SUPPORT_MESSAGE', payload);
      this.reportSent = res.status;
      if (res.status) {
        const userData = GET_USER_DATA();
        const businessData = GET_USER_BUSINESS_DATA();
        const userName = userData.name || '';

        this.supportForm = {
          name: userName,
          firstname: userName.split(' ')[0],
          lastname: userName.split(' ')[1],
          email: businessData.email,
          businessName: businessData.name,
          phone: businessData.phone,
          issueTypes: '',
          issueSubTypes: '',
          subject: '',
          description: '',
          issueType: ''
        };
        this.$refs.observer.reset();
      } else {
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
  }
};
</script>

<style scoped></style>
